/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ACTION_TYPES, getPriceStr } from "./utils"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import PropertySearch from "../../pages/property/for-sale"


const SeoResults = ({ description, lang, meta, title, searchParams, location, noindex,  }) => {

  const {areas, bedrooms, pType, type, price, commType } = searchParams;
  const data = useStaticQuery(graphql`
  {
    strapiMetaData {
      Bulk_Meta_Data {
        meta_data {
          Link
          Meta_Description
          Meta_Title
        }
      }
      Meta_Data {
        id
        Meta_Title
        Meta_Description
        Link
      }
    }
  }
`)
const Meta_Data = data.strapiMetaData;
let metaDatas = [];

if (Meta_Data.Bulk_Meta_Data) {
  Meta_Data.Bulk_Meta_Data.meta_data.forEach((bulk_data) => {
    if (bulk_data.Link) {
      metaDatas.push(bulk_data);
    }
  });
}

if (Meta_Data.Meta_Data) {
  Meta_Data.Meta_Data.forEach((data) => {
    if (data.Link) {
      metaDatas.push(data);
    }
  });
}

metaDatas = [...new Map(metaDatas.map(item => [item.Link, item])).values()];

  const actionStr = ACTION_TYPES[pType];
  const priceStr = getPriceStr(price)
  const bedRooms = searchParams.bedrooms

  let propertyType = ""

  if(type == "") {
    propertyType = "property"
  } else {
    propertyType = type
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

let actionTxt = ""
if(pType === "sales"){
  actionTxt = "sale"
}
else {
  actionTxt = "rent"
}


let desc = "";
  desc = "Discover a wide range of property for "+ actionTxt + " with Anthony Pepe. For more information about the properties available for " + actionTxt + ", please contact Anthony Pepe Estate Agents."

  if ('london' !== areas) {
    desc = "Explore the range of property available for "+actionTxt+" in "+areas+". For more information about the properties available for "+actionTxt+" in "+areas+", please contact Anthony Pepe Estate Agents."
  }
  if(priceStr){
    desc = "Find the best property "+actionTxt+" "+priceStr+" with Anthony Pepe Estate agents. Fill in the form to know more by booking a viewing with one of our specialists in "+areas+"."
  }
  if(propertyType !== "property"){
    desc = "Are you searching for "+propertyType+" "+actionTxt+" in "+areas+"? Anthony Pepe's property experts are here to help."
  }
  if(bedrooms){
    desc ="Find "+bedrooms+" bedroom property "+actionTxt+" in "+areas+" here. Contact one of our experts today to get assistance in finding the perfect property in "+areas+"."
  }
  if(priceStr && propertyType){
    desc = "Browse through the latest collection of "+propertyType+" "+actionTxt+" in "+areas+", available with Anthony Pepe estate in "+areas+"."
  }
  if(priceStr && bedrooms){
    desc = "Explore "+bedrooms+" bedroom properties "+actionTxt+" in "+areas+" "+priceStr+" with Anthony Pepe and Book a Viewing for the property that you are interested in."
  }
  if(propertyType && bedrooms){
    desc = "Explore Check the latest "+bedrooms+" bedroom "+propertyType+" "+actionTxt+" in "+areas+". Fill in the form to know more by booking a viewing with one of our specialists in "+areas+"."
  }
  if(priceStr && propertyType && bedrooms){
    desc = "Find "+bedrooms+" bedroom "+propertyType+" "+actionTxt+" in "+areas+" "+priceStr+" with Anthony Pepe. Send an enquiry to one of our specialists, to get assistance in finding your property in "+areas+"."
  }
  // if ('sales' === pType) {
  //   desc = "2 Discover a wide range of "+ propertyType + actionStr + areas +" with Anthony Pepe. Refine your search using the filters below and for more information about "+ pType+ " property in " + areas + ". please contact Anthony Pepe";

  //   if ('london' !== areas) {
  //       desc = "3 Discover a wide range of "+ propertyType + actionStr + areas +" with Anthony Pepe. Refine your search using the filters below and for more information about "+ pType+ " property in " + areas + ". please contact Anthony Pepe";
  //   }
  // } else {
  //   // price
  //   if (price.min || price.max){
  //     desc = propertyType + actionStr + areas + "."
  //   }

  // }

  
    
    let titlePropertyType = ""

    if(type == "") {
        titlePropertyType = "Property"
    } else if(type == "apartment") {
        titlePropertyType = "Apartment"
    } else if(type == "house") {
        titlePropertyType = "House"
    } else if(type == "maisonette") {
        titlePropertyType = "Maisonette"
    } else if(type == "property") {
        titlePropertyType = "Property"
    } else if(type == "commercial") {
        titlePropertyType = "Commercial"
    } else {
        titlePropertyType = type
    }

    let bedroomType = ""
 
    if ( bedrooms ) {
      bedroomType = bedrooms + ' + Bedroom '
    }
    var { pathname } = useLocation()
    let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr


    const cleanedPathname = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    const cleanedPathnameFinal = cleanedPathname.endsWith('/') ? cleanedPathname.slice(0, -1) : cleanedPathname;

    const matchedMetaData = metaDatas.find(item => {
        const cleanedLink = item.Link.startsWith('/') ? item.Link.substring(1) : item.Link;
        const cleanedLinkFinal = cleanedLink.endsWith('/') ? cleanedLink.slice(0, -1) : cleanedLink;

        const adjustedPathname = cleanedPathnameFinal.replace('property-map', 'property');
        return cleanedLinkFinal === adjustedPathname;
    });

    if (matchedMetaData) {
        descTitle = matchedMetaData.Meta_Title;
        desc = matchedMetaData.Meta_Description;
    }
    

  // console.log("Props Desc => ", desc);

  description = desc
  if (pathname == "/home/" || pathname == "/home" )
    pathname = "/"
  if (pathname == "/property/to-rent/" || pathname == "/property/to-rent" )
    pathname = "/property/to-rent/in-central-london"
  if (pathname == "/property/for-sale/" || pathname == "/property/for-sale" )
    pathname = "/property/for-sale/in-central-london"

  var curentUrl = '';
  curentUrl = process.env.GATSBY_SITE_URL+pathname;

  // set no index for result pagination
  let hasPagination = false;
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
  //set lowercase url
  curentUrl = curentUrl.toLowerCase();

  let metaItems = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: descTitle,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `og:url`,
      content: curentUrl,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `Anthony Pepe`,
    },
    {
      name: `twitter:title`,
      content: descTitle,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);


  // set no index for result pagination
  if(noindex === "noindex") {
    metaItems.push({
      name: `robots`,
      content: `noindex`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical"
        }]}
      title={descTitle}
      titleTemplate={matchedMetaData? `%s` : `%s | Anthony Pepe`}
      meta={metaItems}
    />
  )

}

SeoResults.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  searchParams: [],
}

SeoResults.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  searchParams: PropTypes.any,
}

export default SeoResults
