import React, {  } from 'react';
import { Map } from '@components/icon/icon'
import { Link } from 'gatsby';

function GetmapLink(props) {
    const OpenMap= () => {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        const mapUrl = currentUrl.replace("property", "property-map")
        if (typeof window !== "undefined") {
            window.location = mapUrl
        }
    }
    return (
        <a href="javascript:;" onClick={(e)=>OpenMap()}><Map /> Map</a>
    )
}
export default GetmapLink
